@use "design";

*,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
a,
dl,
dt,
dd,
ol,
ul,
li {
	margin: 0;
	padding: 0;
	list-style: none;

	&:focus-visible {
		outline: 2px solid design.$black;
	}
}

// ::selection {
// 	text-shadow: none;
// }

::-moz-selection {
	text-shadow: none;
}

::-webkit-selection {
	text-shadow: none;
}

button {
	padding: 0;
	border: 1px solid transparent;
	outline: 1px solid transparent;
	box-shadow: none;
	background-color: transparent;
	border-radius: 0;
}

textarea {
	resize: none;
}

textarea,
input,
select {
	font-family: design.$main-font;
}
