@use '../base/design';
@use '../layout/sizes';

.footer {
	border-top: 1px solid design.$bg2;
	background-color: design.$black;

	&__container {
		display: flex;
		justify-content: space-between;
		padding: design.$gap-48 design.$gap-16;

		@media #{sizes.$mobile} {
			align-items: center;
			flex-direction: column;
			justify-content: center;
			padding: design.$gap-24 design.$gap-16;
			row-gap: design.$gap-24;
		}

		@media #{sizes.$tablet} {
			align-items: center;
			flex-direction: column;
			justify-content: center;
			padding: design.$gap-32 design.$gap-32;
			row-gap: design.$gap-32;
		}
	}

	&__copyright {
		display: flex;
		font-weight: 300;
		column-gap: design.$gap-06;

		@media #{sizes.$mobile, sizes.$tablet} {
			order: 3;
			text-align: center;
			flex-direction: column;
			row-gap: design.$gap-12;
		}

		&-author {
			transition: color 0.3s ease;
			text-decoration: underline;
			color: design.$primary;

			@media #{sizes.$hover} {
				&:hover {
					text-decoration: none;
					color: design.$primary-dark;
				}
			}
		}
	}

	&__separator {
		display: none;
		height: 1px;

		@media #{sizes.$mobile, sizes.$tablet} {
			display: block;
			order: 2;
			width: 1.6rem;
			background-color: design.$bg2;
		}

		@media #{sizes.$tablet} {
			width: 2.4rem;
		}
	}

	&__link {
		transition: color 0.3s ease;
		font-weight: 300;
		color: design.$white;

		@media #{sizes.$hover} {
			&:hover {
				color: design.$primary-dark;
			}
		}

		@media #{sizes.$mobile} {
			order: 1;
		}
	}
}
