// colours

$white: #edf5f5;
$white-bg: rgba(243, 243, 243, 0.08);

$black: #18181b;

$primary: #00fdfb;
$primary-dark: #00d8d6;
$primary-darker: #01cfcc;
$primary-darkest:#008987;
$primary-less: rgba(#00fdfb, 0.35);

$grey: #8c8d8d;

$bg: #3b3b41;
$bg2: #48484e;

$xxs: 1.4rem;
$xs: 1.5rem;
$p: 1.6rem;
$h4: 1.8rem;
$h3: 2rem;
$h2: 3.2rem;
$h1: 4.4rem;
$display: 9.6rem;

// margins
$gap-06: 0.6rem;
$gap-12: 1.2rem;
$gap-16: 1.6rem;
$gap-24: 2.4rem;
$gap-32: 3.2rem;
$gap-36: 3.6rem;
$gap-48: 4.8rem;
$gap-64: 6.4rem;
$gap-96: 9.6rem;

// fonts

$main-font: 'Mitr', sans-serif;
$secondary-font: 'Ethnocentric Rg', sans-serif;

// line height

$lh-s: 1.3;
$lh-m: 1.5;
$lh-l: 1.6;
