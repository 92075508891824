@use "../base/design";

::-webkit-scrollbar-track {
	background-color: design.$black;
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar {
	width: 6px;
	background-color: design.$black;
}

::-webkit-scrollbar-thumb {
	background-color: design.$bg2;
}
