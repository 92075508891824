@use 'design';

*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	overflow-x: hidden;
	font-size: 62.5%;
	scroll-behavior: smooth;
}

body {
	overflow-x: hidden;
	position: relative;
	margin: 0;
	font-family: design.$main-font;
	font-weight: 400;
	font-size: design.$p;
	background-color: design.$black;
}

a {
	cursor: pointer;
	transition: all 200ms ease-in-out;
	text-decoration: none;
	font-family: design.$main-font;
	border-radius: 3px;
}

button {
	font-family: design.$main-font;
	font-size: design.$p;
}

p {
	font-family: design.$main-font;
	font-weight: 400;
}

section {
	overflow: hidden;
}

// hr {
// 	margin: 0;
// 	border-color: design.$black-light1;
// 	background-color: design.$black-light1;
// }
