@use '../../base/design';
@use '../../layout/sizes';

.faq {
	position: relative;
	margin-top: 8rem;
	margin-bottom: 0;
	padding-bottom: 10rem;
	background-image: radial-gradient(
			circle,
			rgba(255, 255, 255, 0.05) 1px,
			transparent 0
		),
		radial-gradient(circle, rgba(255, 255, 255, 0.05) 1px, transparent 0);
	background-size: 5rem 5rem;
	background-position: 0 0, 2.5rem 2.5rem;

	@media #{sizes.$mobile, sizes.$tablet} {
		margin-top: 8rem;
		margin-bottom: 8rem;
		padding-bottom: 0;
	}

	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 13rem;
		left: 100%;
		width: 73rem;
		height: 100%;
		transform: translateX(-50%);
		background-image: url('/dist/img/svg/logo-asset.svg'); // Ścieżka do pliku
		background-repeat: no-repeat;
		background-size: contain;

		@media #{sizes.$mobile, sizes.$tablet} {
			display: none;
		}
	}

	&::after {
		content: '';
		display: block;
		position: absolute;
		z-index: 10;
		top: 42rem;
		left: 3.2rem;
		width: 38.9rem;
		height: 75rem;
		background-image: url('/dist/img/svg/logo-pattern.svg'); // Ścieżka do pliku
		background-repeat: no-repeat;
		background-size: contain;

		@media #{sizes.$mobile, sizes.$tablet} {
			display: none;
		}
	}

	&__container {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		row-gap: design.$gap-64;

		@media #{sizes.$mobile} {
			row-gap: 4rem;
		}

		@media #{sizes.$tablet} {
			row-gap: design.$gap-48;
		}
	}

	&__body {
		display: flex;
		flex-direction: column;
		width: calc(8 / 12 * 100%);
		row-gap: design.$gap-16;

		@media #{sizes.$mobile, sizes.$tablet} {
			width: 100%;
		}

		@media #{sizes.$mobile, sizes.$tablet} {
			row-gap: design.$gap-12;
		}
	}

	&__item {
		display: flex;
		z-index: 11;
		overflow: hidden;
		flex-direction: column;
		justify-content: space-between;
		transition: border-color 0.2s ease;
		border: 1px solid design.$primary-less;
		border-radius: 12px;
		background-color: design.$black;

		&--active {
			border-color: design.$primary;
			.faq__item-title {
				border-left: 1px solid design.$primary;
			}
			.faq__toggle {
				border-radius: 6px 6px 0 6px;
				.faq__toggle-anchor {
					transform: rotate(270deg);
				}
			}
			.faq__item-separator {
				height: 1px;
				background-color: design.$primary;
			}

			.faq__item-answer {
				opacity: 1;
			}
		}

		&-question {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
		}

		&-number {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			min-width: 10.2rem;
			max-width: 10.2rem;
			height: 100%;
			text-shadow: 0 0 20px design.$primary;
			font-family: design.$secondary-font;
			font-size: 2.2rem;
			color: design.$primary;

			@media #{sizes.$mobile} {
				min-width: 7.2rem;
				min-width: 7.2rem;
				font-size: design.$xs;
			}

			@media #{sizes.$tablet} {
				min-width: 9.5rem;
				min-width: 9.5rem;
				font-size: design.$h4;
			}
		}

		&-title {
			display: inline-flex;
			align-items: center;
			align-self: stretch;
			flex-grow: 1;
			padding: design.$gap-32;
			transition: border-color 0.3s ease;
			border-left: 1px solid design.$primary-less;
			font-weight: 400;
			font-size: design.$h3;

			@media #{sizes.$mobile} {
				padding: design.$gap-16;
				font-size: design.$xs;
			}

			@media #{sizes.$tablet} {
				padding: design.$gap-24;
				font-size: design.$h4;
			}
		}

		&-separator {
			width: 100%;
			height: 0;
			transition: height 0.3s ease, background-color 0.3s ease;
			background-color: design.$primary-less;
		}

		&-answer {
			max-height: 0;
			padding: 0 9.9rem 0 13.4rem;
			transition: max-height 0.3s ease, opacity 0.3s ease;
			opacity: 0;

			@media #{sizes.$mobile} {
				padding: 0 6.8rem 0 8.8rem;
				font-size: design.$xs;
			}

			@media #{sizes.$tablet} {
				padding: 0 9.3rem 0 11.9rem;
				font-size: design.$p;
			}
		}

		&-text {
			padding: design.$gap-32 0;
			font-weight: 300;

			@media #{sizes.$mobile} {
				padding: design.$gap-16 0;
			}

			@media #{sizes.$tablet} {
				padding: design.$gap-24 0;
			}

			&--semibold {
				font-weight: 500;
			}
		}
		&-link {
			transition: color 0.3s ease;
			color: design.$primary;

			@media #{sizes.$hover} {
				&:hover {
					color: design.$primary-dark;

					span {
						text-decoration: none;
					}
				}
			}

			span {
				text-decoration: underline;
			}
		}
	}

	&__toggle {
		display: flex;
		align-items: center;
		align-self: stretch;
		justify-content: center;
		margin: design.$gap-06 design.$gap-06 design.$gap-06 0;
		padding: design.$gap-32 design.$gap-24;
		cursor: pointer;
		transition: background-color 0.2s ease, border-radius 0.2s ease;
		border-radius: 6px;
		background-color: design.$primary;

		@media #{sizes.$hover} {
			&:hover {
				background-color: design.$primary-dark;
			}
		}

		@media #{sizes.$mobile} {
			padding: design.$gap-32 design.$gap-16;
		}

		@media #{sizes.$tablet} {
			padding: design.$gap-24 design.$gap-24;
		}

		&-anchor {
			transition: transform 0.2s ease;
			transform: rotate(90deg);
		}
	}
}
