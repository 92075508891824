@use '../base/design';

@keyframes glitch {
	0% {
		transform: translate(0, 0);
		text-shadow: none;
	}
	20% {
		transform: translate(-2px, 2px);
		text-shadow: 2px 0 design.$primary, -2px 0 design.$primary-dark;
	}
	40% {
		transform: translate(2px, -2px);
		text-shadow: 2px 2px design.$primary, -2px -2px design.$primary;
	}
	60% {
		transform: translate(-1px, 1px);
		text-shadow: 1px 1px design.$primary, -1px -1px design.$primary;
	}
	80% {
		transform: translate(2px, -2px);
		text-shadow: 3px 0 design.$primary, -3px 0 design.$primary;
	}
	100% {
		transform: translate(0, 0);
		text-shadow: none;
	}
}


