@use '../base/design';
@use 'sizes';

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: design.$secondary-font;
	font-weight: 400;
	color: design.$white;
}

h1 {
	line-height: 1.2;
	font-size: design.$h1;
}

h2 {
	font-weight: 400;
	line-height: 1.2;
	font-size: design.$h2;

	@media #{sizes.$mobile} {
		font-size: 2.4rem;
	}

	@media #{sizes.$tablet} {
		font-size: 3.2rem;
	}
}

h3 {
	line-height: design.$lh-m;
	font-size: design.$h3;
}

h4 {
	line-height: design.$lh-m;
	font-size: design.$h4;
}

p {
	font-family: design.$main-font;
	font-weight: 300;
	line-height: design.$lh-m;
	font-size: design.$p;
	color: design.$white;
}
