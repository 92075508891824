@use '../base/design';
@use '../layout/sizes';

.wrapper {
	position: relative;
	clear: both;
	width: 100%;
	max-width: 128rem;
	height: auto;
	margin: 0 auto;
	padding: 0 1.6rem;

	@media #{sizes.$mobile} {
		padding: 0 1.6rem;
	}

	@media #{sizes.$tablet} {
		padding: 0 3.2rem;
	}
}

.section {
	margin: 12rem 0;

	@media #{sizes.$mobile} {
		margin: 8rem 0;
	}

	&__header {
		display: flex;
		position: relative;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		padding-top: 11rem;

		@media #{sizes.$mobile} {
			max-width: 100%;
			padding-top: 4.4rem;
		}

		@media #{sizes.$tablet} {
			max-width: calc(6 / 6 * 100%);
			padding-top: 5rem;
		}

		&-subtitle {
			position: absolute;
			top: 0;
			font-family: design.$secondary-font;
			font-size: 16.6rem;
			color: design.$white-bg;

			@media #{sizes.$mobile} {
				display: inline-block;
				max-width: 100%;
				text-align: center;
				text-wrap: stable;
				word-wrap: break-word;
				font-size: 8rem;
			}

			@media #{sizes.$tablet} {
				display: inline-block;
				max-width: 100%;
				text-align: center;
				text-wrap: stable;
				word-wrap: break-word;
				font-size: 9.6rem;
			}
		}

		&-info {
			display: flex;
			flex-direction: column;
			max-width: calc(6 / 10 * 100%);
			text-align: center;
			row-gap: design.$gap-24;
			justify-content: center;
			align-items: center;

			@media #{sizes.$mobile} {
				row-gap: design.$gap-16;
				max-width: 100%;
			}

			@media #{sizes.$tablet} {
				max-width: calc(6 / 6 * 100%);
			}
		}

		&-title {
			letter-spacing: 2px;
		}

		&-caption {
			@media #{sizes.$tablet} {
				max-width: calc(4 / 6 * 100%);
			}
		}
	}
}

.hidden {
	display: none !important;
}

.mobile-off {
	@media #{sizes.$mobile} {
		display: none;
	}
}

.tablet-off {
	@media #{sizes.$tablet} {
		display: none;
	}
}
