@use '../base/design';
@use 'sizes';

.btn {
	display: inline-flex;
	position: relative;
	overflow: hidden;
	align-items: center;
	width: fit-content;
	font-weight: 500;

	@media #{sizes.$hover} {
		&:hover {
			cursor: pointer;
		}
	}

	span {
		position: relative;
		z-index: 3;
		transition: color 0.2s;
	}

	&--cta {
		padding: design.$gap-24 design.$gap-48;
		border-radius: 12px;

		@media #{sizes.$mobile} {
			border-radius: 8px;
			padding: design.$gap-16 design.$gap-32;
		}

		@media #{sizes.$tablet} {
			padding: design.$gap-24 design.$gap-48;
		}
	}

	&--primary {
		color: design.$black;
		background-color: design.$primary;
		box-shadow: 0 0 30px design.$primary;
		text-transform: capitalize;

		@media #{sizes.$hover} {
			&:hover {
				background-color: design.$primary-darker;
				box-shadow: 0 0 30px design.$primary-darker;
			}
		}

		@media #{sizes.$mobile} {
			box-shadow: 0 0 15px design.$primary;
		}
	}
}
