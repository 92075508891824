@use '../../base/design';
@use '../../layout/sizes';

.hero {
	position: relative;
	overflow: visible;
	width: 100vw;
	height: 100vh;
	background-image: url(/dist/img/index/hero_bg.webp);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	@media #{sizes.$mobile} {
		background-position: 60%;
	}

	@media #{sizes.$tablet} {
		background-position: 65%;
	}

	&__container {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		width: fit-content;
		height: 100%;
	}

	&__title {
		margin-bottom: design.$gap-06;
		text-align: center;
		text-transform: uppercase;
		font-family: design.$main-font;
		font-weight: 400;
		font-size: design.$h4;
		color: design.$white;

		@media #{sizes.$mobile} {
			font-size: design.$xs;
		}
	}

	&__countdown {
		display: flex;
		align-items: flex-start;
		justify-content: center;

		&-item {
			display: flex;
			align-items: center;
			flex-direction: column;
		}

		&-digits {
			font-family: design.$secondary-font;
			font-size: design.$display;
			color: design.$white;

			@media #{sizes.$mobile} {
				font-size: 3.6rem;
			}

			@media #{sizes.$tablet} {
				font-size: 7.2rem;
			}
		}

		&-separator {
			font-family: design.$secondary-font;
			font-size: design.$display;
			color: design.$white;

			@media #{sizes.$mobile} {
				font-size: 3.6rem;
			}

			@media #{sizes.$tablet} {
				font-size: 7.6rem;
			}
		}

		&-label {
			font-size: design.$h4;
			color: design.$grey;

			@media #{sizes.$mobile} {
				font-size: design.$xxs;
			}

			@media #{sizes.$tablet} {
				font-size: design.$p;
			}
		}
	}

	&__btn {
		margin-top: design.$gap-64;
		font-size: design.$h4;

		@media #{sizes.$mobile} {
			font-size: design.$xs;
		}

		@media #{sizes.$tablet} {
			font-size: design.$h4;
		}
	}

	.changed {
		animation: glitch 0.4s linear 1;
	}

	&__more {
		display: flex;
		position: absolute;
		bottom: 0;
		left: 50%;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		transform: translate(-50%, calc(50% - 1.35rem));

		@media #{sizes.$mobile} {
			transform: translate(-50%, calc(50% - 1rem));
		}

		@media #{sizes.$tablet} {
			transform: translate(-50%, calc(50% - 1.3rem));
		}

		&-caption {
			font-weight: 300;
			font-size: design.$h4;
			color: design.$white;

			@media #{sizes.$mobile} {
				font-size: design.$xs;
			}

			@media #{sizes.$tablet} {
				font-size: design.$h4;
			}
		}

		&-line {
			@media #{sizes.$mobile} {
				width: 25%;
				max-height: 14.2rem;
			}

			@media #{sizes.$tablet} {
				max-height: 18.2rem;
			}
		}
	}
}
