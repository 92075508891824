@use '../base/design';
@use '../layout/sizes';

.header {
	position: absolute;
	z-index: 2;
	top: design.$gap-32;
	left: 50%;
	transform: translateX(-50%);

	@media #{sizes.$tablet} {
		top: design.$gap-64;
	}

	&__container {
		display: grid;
		align-items: center;
		grid-template-columns: 1fr auto 1fr;
		column-gap: design.$gap-48;

		@media #{sizes.$mobile} {
			column-gap: design.$gap-24;
			grid-template-columns: 1fr auto 1fr;
		}

		@media #{sizes.$tablet} {
			column-gap: design.$gap-48;
			grid-template-columns: 1fr auto 1fr;
		}
	}

	&__img {
		display: grid;
		min-width: 24.2rem;

		@media #{sizes.$mobile} {
			min-width: 10.7rem;
			max-width: 10.7rem;
		}

		@media #{sizes.$tablet} {
			min-width: 17.8rem;
			max-width: 17.8rem;
		}
	}

	&__link {
		cursor: pointer;
		font-size: 0;

		@media #{sizes.$hover} {
			&:hover {
				.header__logo {
					filter: drop-shadow(0 0 20px design.$primary-darkest);
				}
			}
		}
	}

	&__logo {
		max-width: 12.74rem;
		height: auto;
		transition: filter 0.3s ease;
		justify-self: center;

		@media #{sizes.$mobile} {
			max-width: 7.2rem;
			height: auto;
		}

		@media #{sizes.$tablet} {
			max-width: 12rem;
			height: auto;
		}
	}

	&__date {
		justify-self: end;

		@media #{sizes.$mobile} {
			width: auto;
			max-height: 2.6rem;
		}

		@media #{sizes.$tablet} {
			width: auto;
			max-height: 4.6rem;
		}
	}

	&__year {
		justify-self: start;

		@media #{sizes.$mobile} {
			width: auto;
			max-height: 2.6rem;
		}

		@media #{sizes.$tablet} {
			width: auto;
			max-height: 4.6rem;
		}
	}
}
