address,
blockquote,
dd,
dl,
fieldset,
form,
ol,
p,
pre,
table,
ul {
	margin-bottom: 0;
}
