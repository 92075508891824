*,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
a,
dl,
dt,
dd,
ol,
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
*:focus-visible,
h1:focus-visible,
h2:focus-visible,
h3:focus-visible,
h4:focus-visible,
h5:focus-visible,
h6:focus-visible,
p:focus-visible,
ul:focus-visible,
a:focus-visible,
dl:focus-visible,
dt:focus-visible,
dd:focus-visible,
ol:focus-visible,
ul:focus-visible,
li:focus-visible {
  outline: 2px solid #18181b;
}

::-moz-selection {
  text-shadow: none;
}

::-webkit-selection {
  text-shadow: none;
}

button {
  padding: 0;
  border: 1px solid transparent;
  outline: 1px solid transparent;
  box-shadow: none;
  background-color: transparent;
  border-radius: 0;
}

textarea {
  resize: none;
}

textarea,
input,
select {
  font-family: "Mitr", sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  overflow-x: hidden;
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  position: relative;
  margin: 0;
  font-family: "Mitr", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  background-color: #18181b;
}

a {
  cursor: pointer;
  transition: all 200ms ease-in-out;
  text-decoration: none;
  font-family: "Mitr", sans-serif;
  border-radius: 3px;
}

button {
  font-family: "Mitr", sans-serif;
  font-size: 1.6rem;
}

p {
  font-family: "Mitr", sans-serif;
  font-weight: 400;
}

section {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Ethnocentric Rg", sans-serif;
  font-weight: 400;
  color: #edf5f5;
}

h1 {
  line-height: 1.2;
  font-size: 4.4rem;
}

h2 {
  font-weight: 400;
  line-height: 1.2;
  font-size: 3.2rem;
}
@media (min-width: 360px) and (max-width: 759px) {
  h2 {
    font-size: 2.4rem;
  }
}
@media (min-width: 760px) and (max-width: 1199px) {
  h2 {
    font-size: 3.2rem;
  }
}

h3 {
  line-height: 1.5;
  font-size: 2rem;
}

h4 {
  line-height: 1.5;
  font-size: 1.8rem;
}

p {
  font-family: "Mitr", sans-serif;
  font-weight: 300;
  line-height: 1.5;
  font-size: 1.6rem;
  color: #edf5f5;
}

address,
blockquote,
dd,
dl,
fieldset,
form,
ol,
p,
pre,
table,
ul {
  margin-bottom: 0;
}

.btn {
  display: inline-flex;
  position: relative;
  overflow: hidden;
  align-items: center;
  width: fit-content;
  font-weight: 500;
}
@media (hover: hover) and (pointer: fine) {
  .btn:hover {
    cursor: pointer;
  }
}
.btn span {
  position: relative;
  z-index: 3;
  transition: color 0.2s;
}
.btn--cta {
  padding: 2.4rem 4.8rem;
  border-radius: 12px;
}
@media (min-width: 360px) and (max-width: 759px) {
  .btn--cta {
    border-radius: 8px;
    padding: 1.6rem 3.2rem;
  }
}
@media (min-width: 760px) and (max-width: 1199px) {
  .btn--cta {
    padding: 2.4rem 4.8rem;
  }
}
.btn--primary {
  color: #18181b;
  background-color: #00fdfb;
  box-shadow: 0 0 30px #00fdfb;
  text-transform: capitalize;
}
@media (hover: hover) and (pointer: fine) {
  .btn--primary:hover {
    background-color: #01cfcc;
    box-shadow: 0 0 30px #01cfcc;
  }
}
@media (min-width: 360px) and (max-width: 759px) {
  .btn--primary {
    box-shadow: 0 0 15px #00fdfb;
  }
}

.wrapper {
  position: relative;
  clear: both;
  width: 100%;
  max-width: 128rem;
  height: auto;
  margin: 0 auto;
  padding: 0 1.6rem;
}
@media (min-width: 360px) and (max-width: 759px) {
  .wrapper {
    padding: 0 1.6rem;
  }
}
@media (min-width: 760px) and (max-width: 1199px) {
  .wrapper {
    padding: 0 3.2rem;
  }
}

.section {
  margin: 12rem 0;
}
@media (min-width: 360px) and (max-width: 759px) {
  .section {
    margin: 8rem 0;
  }
}
.section__header {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 11rem;
}
@media (min-width: 360px) and (max-width: 759px) {
  .section__header {
    max-width: 100%;
    padding-top: 4.4rem;
  }
}
@media (min-width: 760px) and (max-width: 1199px) {
  .section__header {
    max-width: 100%;
    padding-top: 5rem;
  }
}
.section__header-subtitle {
  position: absolute;
  top: 0;
  font-family: "Ethnocentric Rg", sans-serif;
  font-size: 16.6rem;
  color: rgba(243, 243, 243, 0.08);
}
@media (min-width: 360px) and (max-width: 759px) {
  .section__header-subtitle {
    display: inline-block;
    max-width: 100%;
    text-align: center;
    text-wrap: stable;
    word-wrap: break-word;
    font-size: 8rem;
  }
}
@media (min-width: 760px) and (max-width: 1199px) {
  .section__header-subtitle {
    display: inline-block;
    max-width: 100%;
    text-align: center;
    text-wrap: stable;
    word-wrap: break-word;
    font-size: 9.6rem;
  }
}
.section__header-info {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  text-align: center;
  row-gap: 2.4rem;
  justify-content: center;
  align-items: center;
}
@media (min-width: 360px) and (max-width: 759px) {
  .section__header-info {
    row-gap: 1.6rem;
    max-width: 100%;
  }
}
@media (min-width: 760px) and (max-width: 1199px) {
  .section__header-info {
    max-width: 100%;
  }
}
.section__header-title {
  letter-spacing: 2px;
}
@media (min-width: 760px) and (max-width: 1199px) {
  .section__header-caption {
    max-width: 66.6666666667%;
  }
}

.hidden {
  display: none !important;
}

@media (min-width: 360px) and (max-width: 759px) {
  .mobile-off {
    display: none;
  }
}

@media (min-width: 760px) and (max-width: 1199px) {
  .tablet-off {
    display: none;
  }
}

@keyframes glitch {
  0% {
    transform: translate(0, 0);
    text-shadow: none;
  }
  20% {
    transform: translate(-2px, 2px);
    text-shadow: 2px 0 #00fdfb, -2px 0 #00d8d6;
  }
  40% {
    transform: translate(2px, -2px);
    text-shadow: 2px 2px #00fdfb, -2px -2px #00fdfb;
  }
  60% {
    transform: translate(-1px, 1px);
    text-shadow: 1px 1px #00fdfb, -1px -1px #00fdfb;
  }
  80% {
    transform: translate(2px, -2px);
    text-shadow: 3px 0 #00fdfb, -3px 0 #00fdfb;
  }
  100% {
    transform: translate(0, 0);
    text-shadow: none;
  }
}
::-webkit-scrollbar-track {
  background-color: #18181b;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #18181b;
}

::-webkit-scrollbar-thumb {
  background-color: #48484e;
}

.header {
  position: absolute;
  z-index: 2;
  top: 3.2rem;
  left: 50%;
  transform: translateX(-50%);
}
@media (min-width: 760px) and (max-width: 1199px) {
  .header {
    top: 6.4rem;
  }
}
.header__container {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 4.8rem;
}
@media (min-width: 360px) and (max-width: 759px) {
  .header__container {
    column-gap: 2.4rem;
    grid-template-columns: 1fr auto 1fr;
  }
}
@media (min-width: 760px) and (max-width: 1199px) {
  .header__container {
    column-gap: 4.8rem;
    grid-template-columns: 1fr auto 1fr;
  }
}
.header__img {
  display: grid;
  min-width: 24.2rem;
}
@media (min-width: 360px) and (max-width: 759px) {
  .header__img {
    min-width: 10.7rem;
    max-width: 10.7rem;
  }
}
@media (min-width: 760px) and (max-width: 1199px) {
  .header__img {
    min-width: 17.8rem;
    max-width: 17.8rem;
  }
}
.header__link {
  cursor: pointer;
  font-size: 0;
}
@media (hover: hover) and (pointer: fine) {
  .header__link:hover .header__logo {
    filter: drop-shadow(0 0 20px #008987);
  }
}
.header__logo {
  max-width: 12.74rem;
  height: auto;
  transition: filter 0.3s ease;
  justify-self: center;
}
@media (min-width: 360px) and (max-width: 759px) {
  .header__logo {
    max-width: 7.2rem;
    height: auto;
  }
}
@media (min-width: 760px) and (max-width: 1199px) {
  .header__logo {
    max-width: 12rem;
    height: auto;
  }
}
.header__date {
  justify-self: end;
}
@media (min-width: 360px) and (max-width: 759px) {
  .header__date {
    width: auto;
    max-height: 2.6rem;
  }
}
@media (min-width: 760px) and (max-width: 1199px) {
  .header__date {
    width: auto;
    max-height: 4.6rem;
  }
}
.header__year {
  justify-self: start;
}
@media (min-width: 360px) and (max-width: 759px) {
  .header__year {
    width: auto;
    max-height: 2.6rem;
  }
}
@media (min-width: 760px) and (max-width: 1199px) {
  .header__year {
    width: auto;
    max-height: 4.6rem;
  }
}

.hero {
  position: relative;
  overflow: visible;
  width: 100vw;
  height: 100vh;
  background-image: url(/dist/img/index/hero_bg.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media (min-width: 360px) and (max-width: 759px) {
  .hero {
    background-position: 60%;
  }
}
@media (min-width: 760px) and (max-width: 1199px) {
  .hero {
    background-position: 65%;
  }
}
.hero__container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  height: 100%;
}
.hero__title {
  margin-bottom: 0.6rem;
  text-align: center;
  text-transform: uppercase;
  font-family: "Mitr", sans-serif;
  font-weight: 400;
  font-size: 1.8rem;
  color: #edf5f5;
}
@media (min-width: 360px) and (max-width: 759px) {
  .hero__title {
    font-size: 1.5rem;
  }
}
.hero__countdown {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.hero__countdown-item {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.hero__countdown-digits {
  font-family: "Ethnocentric Rg", sans-serif;
  font-size: 9.6rem;
  color: #edf5f5;
}
@media (min-width: 360px) and (max-width: 759px) {
  .hero__countdown-digits {
    font-size: 3.6rem;
  }
}
@media (min-width: 760px) and (max-width: 1199px) {
  .hero__countdown-digits {
    font-size: 7.2rem;
  }
}
.hero__countdown-separator {
  font-family: "Ethnocentric Rg", sans-serif;
  font-size: 9.6rem;
  color: #edf5f5;
}
@media (min-width: 360px) and (max-width: 759px) {
  .hero__countdown-separator {
    font-size: 3.6rem;
  }
}
@media (min-width: 760px) and (max-width: 1199px) {
  .hero__countdown-separator {
    font-size: 7.6rem;
  }
}
.hero__countdown-label {
  font-size: 1.8rem;
  color: #8c8d8d;
}
@media (min-width: 360px) and (max-width: 759px) {
  .hero__countdown-label {
    font-size: 1.4rem;
  }
}
@media (min-width: 760px) and (max-width: 1199px) {
  .hero__countdown-label {
    font-size: 1.6rem;
  }
}
.hero__btn {
  margin-top: 6.4rem;
  font-size: 1.8rem;
}
@media (min-width: 360px) and (max-width: 759px) {
  .hero__btn {
    font-size: 1.5rem;
  }
}
@media (min-width: 760px) and (max-width: 1199px) {
  .hero__btn {
    font-size: 1.8rem;
  }
}
.hero .changed {
  animation: glitch 0.4s linear 1;
}
.hero__more {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 50%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transform: translate(-50%, calc(50% - 1.35rem));
}
@media (min-width: 360px) and (max-width: 759px) {
  .hero__more {
    transform: translate(-50%, calc(50% - 1rem));
  }
}
@media (min-width: 760px) and (max-width: 1199px) {
  .hero__more {
    transform: translate(-50%, calc(50% - 1.3rem));
  }
}
.hero__more-caption {
  font-weight: 300;
  font-size: 1.8rem;
  color: #edf5f5;
}
@media (min-width: 360px) and (max-width: 759px) {
  .hero__more-caption {
    font-size: 1.5rem;
  }
}
@media (min-width: 760px) and (max-width: 1199px) {
  .hero__more-caption {
    font-size: 1.8rem;
  }
}
@media (min-width: 360px) and (max-width: 759px) {
  .hero__more-line {
    width: 25%;
    max-height: 14.2rem;
  }
}
@media (min-width: 760px) and (max-width: 1199px) {
  .hero__more-line {
    max-height: 18.2rem;
  }
}

.faq {
  position: relative;
  margin-top: 8rem;
  margin-bottom: 0;
  padding-bottom: 10rem;
  background-image: radial-gradient(circle, rgba(255, 255, 255, 0.05) 1px, transparent 0), radial-gradient(circle, rgba(255, 255, 255, 0.05) 1px, transparent 0);
  background-size: 5rem 5rem;
  background-position: 0 0, 2.5rem 2.5rem;
}
@media (min-width: 360px) and (max-width: 759px), (min-width: 760px) and (max-width: 1199px) {
  .faq {
    margin-top: 8rem;
    margin-bottom: 8rem;
    padding-bottom: 0;
  }
}
.faq::before {
  content: "";
  display: block;
  position: absolute;
  top: 13rem;
  left: 100%;
  width: 73rem;
  height: 100%;
  transform: translateX(-50%);
  background-image: url("/dist/img/svg/logo-asset.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
@media (min-width: 360px) and (max-width: 759px), (min-width: 760px) and (max-width: 1199px) {
  .faq::before {
    display: none;
  }
}
.faq::after {
  content: "";
  display: block;
  position: absolute;
  z-index: 10;
  top: 42rem;
  left: 3.2rem;
  width: 38.9rem;
  height: 75rem;
  background-image: url("/dist/img/svg/logo-pattern.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
@media (min-width: 360px) and (max-width: 759px), (min-width: 760px) and (max-width: 1199px) {
  .faq::after {
    display: none;
  }
}
.faq__container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  row-gap: 6.4rem;
}
@media (min-width: 360px) and (max-width: 759px) {
  .faq__container {
    row-gap: 4rem;
  }
}
@media (min-width: 760px) and (max-width: 1199px) {
  .faq__container {
    row-gap: 4.8rem;
  }
}
.faq__body {
  display: flex;
  flex-direction: column;
  width: 66.6666666667%;
  row-gap: 1.6rem;
}
@media (min-width: 360px) and (max-width: 759px), (min-width: 760px) and (max-width: 1199px) {
  .faq__body {
    width: 100%;
  }
}
@media (min-width: 360px) and (max-width: 759px), (min-width: 760px) and (max-width: 1199px) {
  .faq__body {
    row-gap: 1.2rem;
  }
}
.faq__item {
  display: flex;
  z-index: 11;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  transition: border-color 0.2s ease;
  border: 1px solid rgba(0, 253, 251, 0.35);
  border-radius: 12px;
  background-color: #18181b;
}
.faq__item--active {
  border-color: #00fdfb;
}
.faq__item--active .faq__item-title {
  border-left: 1px solid #00fdfb;
}
.faq__item--active .faq__toggle {
  border-radius: 6px 6px 0 6px;
}
.faq__item--active .faq__toggle .faq__toggle-anchor {
  transform: rotate(270deg);
}
.faq__item--active .faq__item-separator {
  height: 1px;
  background-color: #00fdfb;
}
.faq__item--active .faq__item-answer {
  opacity: 1;
}
.faq__item-question {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.faq__item-number {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 10.2rem;
  max-width: 10.2rem;
  height: 100%;
  text-shadow: 0 0 20px #00fdfb;
  font-family: "Ethnocentric Rg", sans-serif;
  font-size: 2.2rem;
  color: #00fdfb;
}
@media (min-width: 360px) and (max-width: 759px) {
  .faq__item-number {
    min-width: 7.2rem;
    min-width: 7.2rem;
    font-size: 1.5rem;
  }
}
@media (min-width: 760px) and (max-width: 1199px) {
  .faq__item-number {
    min-width: 9.5rem;
    min-width: 9.5rem;
    font-size: 1.8rem;
  }
}
.faq__item-title {
  display: inline-flex;
  align-items: center;
  align-self: stretch;
  flex-grow: 1;
  padding: 3.2rem;
  transition: border-color 0.3s ease;
  border-left: 1px solid rgba(0, 253, 251, 0.35);
  font-weight: 400;
  font-size: 2rem;
}
@media (min-width: 360px) and (max-width: 759px) {
  .faq__item-title {
    padding: 1.6rem;
    font-size: 1.5rem;
  }
}
@media (min-width: 760px) and (max-width: 1199px) {
  .faq__item-title {
    padding: 2.4rem;
    font-size: 1.8rem;
  }
}
.faq__item-separator {
  width: 100%;
  height: 0;
  transition: height 0.3s ease, background-color 0.3s ease;
  background-color: rgba(0, 253, 251, 0.35);
}
.faq__item-answer {
  max-height: 0;
  padding: 0 9.9rem 0 13.4rem;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  opacity: 0;
}
@media (min-width: 360px) and (max-width: 759px) {
  .faq__item-answer {
    padding: 0 6.8rem 0 8.8rem;
    font-size: 1.5rem;
  }
}
@media (min-width: 760px) and (max-width: 1199px) {
  .faq__item-answer {
    padding: 0 9.3rem 0 11.9rem;
    font-size: 1.6rem;
  }
}
.faq__item-text {
  padding: 3.2rem 0;
  font-weight: 300;
}
@media (min-width: 360px) and (max-width: 759px) {
  .faq__item-text {
    padding: 1.6rem 0;
  }
}
@media (min-width: 760px) and (max-width: 1199px) {
  .faq__item-text {
    padding: 2.4rem 0;
  }
}
.faq__item-text--semibold {
  font-weight: 500;
}
.faq__item-link {
  transition: color 0.3s ease;
  color: #00fdfb;
}
@media (hover: hover) and (pointer: fine) {
  .faq__item-link:hover {
    color: #00d8d6;
  }
  .faq__item-link:hover span {
    text-decoration: none;
  }
}
.faq__item-link span {
  text-decoration: underline;
}
.faq__toggle {
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  margin: 0.6rem 0.6rem 0.6rem 0;
  padding: 3.2rem 2.4rem;
  cursor: pointer;
  transition: background-color 0.2s ease, border-radius 0.2s ease;
  border-radius: 6px;
  background-color: #00fdfb;
}
@media (hover: hover) and (pointer: fine) {
  .faq__toggle:hover {
    background-color: #00d8d6;
  }
}
@media (min-width: 360px) and (max-width: 759px) {
  .faq__toggle {
    padding: 3.2rem 1.6rem;
  }
}
@media (min-width: 760px) and (max-width: 1199px) {
  .faq__toggle {
    padding: 2.4rem 2.4rem;
  }
}
.faq__toggle-anchor {
  transition: transform 0.2s ease;
  transform: rotate(90deg);
}

.footer {
  border-top: 1px solid #48484e;
  background-color: #18181b;
}
.footer__container {
  display: flex;
  justify-content: space-between;
  padding: 4.8rem 1.6rem;
}
@media (min-width: 360px) and (max-width: 759px) {
  .footer__container {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 2.4rem 1.6rem;
    row-gap: 2.4rem;
  }
}
@media (min-width: 760px) and (max-width: 1199px) {
  .footer__container {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 3.2rem 3.2rem;
    row-gap: 3.2rem;
  }
}
.footer__copyright {
  display: flex;
  font-weight: 300;
  column-gap: 0.6rem;
}
@media (min-width: 360px) and (max-width: 759px), (min-width: 760px) and (max-width: 1199px) {
  .footer__copyright {
    order: 3;
    text-align: center;
    flex-direction: column;
    row-gap: 1.2rem;
  }
}
.footer__copyright-author {
  transition: color 0.3s ease;
  text-decoration: underline;
  color: #00fdfb;
}
@media (hover: hover) and (pointer: fine) {
  .footer__copyright-author:hover {
    text-decoration: none;
    color: #00d8d6;
  }
}
.footer__separator {
  display: none;
  height: 1px;
}
@media (min-width: 360px) and (max-width: 759px), (min-width: 760px) and (max-width: 1199px) {
  .footer__separator {
    display: block;
    order: 2;
    width: 1.6rem;
    background-color: #48484e;
  }
}
@media (min-width: 760px) and (max-width: 1199px) {
  .footer__separator {
    width: 2.4rem;
  }
}
.footer__link {
  transition: color 0.3s ease;
  font-weight: 300;
  color: #edf5f5;
}
@media (hover: hover) and (pointer: fine) {
  .footer__link:hover {
    color: #00d8d6;
  }
}
@media (min-width: 360px) and (max-width: 759px) {
  .footer__link {
    order: 1;
  }
}